import "./HelpTC.css";

export default function HelpTC() {
    return (
        <div className="">
            <div className="flex flex-col place-items-stretch sm:flex-row">
                <div className="flex flex-col w-full p-4 bg-white place-items-center sm:w-1/4">
                    <div className="w-1/3 p-2 sm:w-full sm:aspect-square sm:p-6 md:p-10 lg:p-16 xl:p-24">
                        <img src={require('../assets/emote-personalize.png')} />
                    </div>
                    <div className="text-xl font-semibold">
                        Personalised experience
                    </div>
                </div>
                <div className="border-2 border-dark-brown"></div>
                <div className="flex flex-col w-full p-4 bg-white place-items-center sm:w-1/4">
                    <div className="w-1/3 p-2 sm:w-full sm:aspect-square sm:p-6 md:p-10 lg:p-16 xl:p-24">
                        <img src={require('../assets/emote-payments.png')} />
                    </div>
                    <div className="text-xl font-semibold">
                        Agnostic payments
                    </div>
                </div>
                <div className="border-2 border-dark-brown"></div>
                <div className="flex flex-col w-full p-4 bg-white place-items-center sm:w-1/4">
                    <div className="w-1/3 p-2 sm:w-full sm:aspect-square sm:p-6 md:p-10 lg:p-16 xl:p-24">
                        <img src={require('../assets/emote-desks.png')} />
                    </div>
                    <div className="text-xl font-semibold">
                        Granular privacy settings
                    </div>
                </div>
                <div className="border-2 border-dark-brown"></div>
                <div className="flex flex-col w-full p-4 bg-white place-items-center sm:w-1/4">
                    <div className="w-1/3 p-2 sm:w-full sm:aspect-square sm:p-6 md:p-10 lg:p-16 xl:p-24">
                        <img src={require('../assets/emote-client-owns-files.png')} />

                    </div>
                    <div className="text-xl font-semibold">
                        Autonomy
                    </div>
                </div>
            </div>
        </div>
    )
}