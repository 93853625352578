import "./HWFooter.css";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";


export default function FTFooter() {
    return (
        <div className="hwfooter-container">
            <div className="char1-container">
                <img src={require('../assets/emote-thera-desk-foot.png')}  />
            </div>
            <div className="hilltop">
                <Logo />
                <div className="motto">
                    It's ok to not be ok
                </div>
                <div>
                    <SocialButtons />
                </div>
            </div>
        </div>
    )
}