import { Outlet, Link } from "react-router-dom";
import "./Layout.css";
// import logo from "../assets/logoScreenshot.png";
// import DrawerAppBar from "./DrawerAppBar";
import Logo from "./Logo";

export default function Layout({ bgColor, setBgColor }) {
  // const handleInvestmentPackagesClickScroll = () => {
  //   const element = document.getElementById("investment-packages");
  //   if (element) {
  //     element.scrollIntoView({ behavior: "smooth" });
  //   }
  // };
  return (
    <div className="bg-dark-brown">
      <div class="relative bg-dark-yellow">
        <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
          <div class="sm:px-16 sm:text-center">
            <p class="font-medium text-black">
              <span class="md:hidden">Get our latest updates on Substack!</span>
              <span class="hidden md:inline">
                Welcome! Please find our latest updates on Substack.
              </span>
              <span class="block sm:ml-2 sm:inline-block">
                <a
                  href="https://emotecare.substack.com/p/almost-october-newsletter"
                  class="font-bold text-black underline"
                >
                  Ok
                  <span aria-hidden="true"> &rarr;</span>
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      {/*<div class="relative bg-primary-green">
        <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
          <div class="sm:px-16 sm:text-center">
            <p class="font-medium text-2xl text-black">
              <span class="md:hidden">Learn about our investment packages</span>
              <span class="hidden md:inline">
                Learn about our investment packages
              </span>
              <span class="block sm:ml-1 sm:inline-block">
                <span
                  class="font-bold text-black underline cursor-pointer"
                  onClick={handleInvestmentPackagesClickScroll}
                >
                  here
                </span>
              </span>
            </p>
          </div>
        </div>
      </div>*/}
      <nav className="desktop-nav">
        <Link
          to="/"
          className="link-item"
          style={{ position: "relative", bottom: "0vh" }}
          onClick={() => setBgColor("default")}
        >
          <Logo />
        </Link>
      </nav>
      {/* An <Outlet> renders whatever child route is currently active,
            so you can think about this <Outlet> as a placeholder for
            the child routes we defined above. */}
      <Outlet />
    </div>
  );
}
