import "./HowItWorks.css";

import { useState,useEffect } from "react"
import BlackButton from "./BlackButton";
import JoinWaitlist from "./JoinWaitlist";
import JoinMaillistForm from "../Components/JoinMaillistForm"
import HWFooter from "./HWFooter";

export default function HowItWorks({setBgColor}) {

    const [isJoining, setIsJoining] = useState(false);

    useEffect(() => {
        setBgColor('orange');
    },[])

    return (
        <div style={{backgroundColor: "#FDBD48"}}>
            <div className="howitworks-top">
                <div className="about-header">
                    <h2>
                        We've created a private and secure marketplace for connecting clients and therapists using web 3.0 technology
                    </h2>
                    <p>
                        Built by therapists for an empowering, digified and flexible therapy experience. emote Care provides its users with rigorous security, granular privacy settings, and a rewarding therapy experience.
                    </p>
                    <div onClick={() => setIsJoining(true) }>
                        <BlackButton title={"Join waitlist"} />
                    </div>
                </div>
                <div>
                    <img src={require('../assets/emote-connected.png')} />
                </div>
            </div>
            {isJoining && <JoinMaillistForm setIsJoining={setIsJoining}/>}
            <div className="howitworks-mid">
                <h2>
                    Here's how we're revolutionizing the industry and providing great accessibility to therapy without compromosing security.
                </h2>
                <p className="sub-p">Here's how</p>
                <div className="works-card">
                    <div className="works-card-left">
                        <img src={require('../assets/emote-anonclient.png')} />
                    </div>
                    <div>
                        <h5>GRANULAR PRIVACY SETTINGS</h5>
                        <h3>Added Privacy layers</h3>
                        <p>
                            By providing flexible and thorough privacy settings, people living in sensative or dangerous situations can still access therapy in complete anonymity - including a full incognito mode for our app.
                        </p>
                        <p>
                            Additionally, users can choose to release or withhold any of their information at any time, offering complete autonomy of choice.
                        </p>
                    </div>
                </div>
                <div className="works-card card2bg">
                    <div className="works-card-left">
                        <h5>OFF CHAIN ENCRYPTED DATA STORAGE</h5>
                        <h3>Full ownership of personal data lies with the client</h3>
                        <p>
                            DLT technology gives data ownership to the people who matter most - you! Also, by storing all data and health records off-chain through decentralised validators who never have access to your information, personal information has never been safer.
                        </p>
                    </div>
                    <div className="works-card-right">
                        <img src={require('../assets/emote-client-owns-files.png')} />
                    </div>
                </div>
                <div className="works-card">
                    <div className="works-card-left">
                        <img src={require('../assets/emote-payments2.png')}/>
                    </div>
                    <div className="works-card-right">
                        <h5>FLEXIBLE COSTING</h5>
                        <h3>Secure agnostic payments for both therapists and clients</h3>
                        <p>
                            Regardless of what currency a client chooses to pay with, the therapist will always receive payment in their own currency of choice - even if that's a digital asset. We also offer several different fee models including pay-as-you-use for those not keen on fixed 50 minute sessions.
                        </p>
                    </div>
                </div>
                <div className="works-card card2bg">
                    <div className="works-card-left">
                        <h5>GLOBALISATION</h5>
                        <h3>Increasing pathways to therapy through web 3.0</h3>
                        <p>
                            By removing borders, web 3.0 opens us the world to shared opportunity and access to therapists from wherever they want. Better representation amongst therapists, more diversity and genuine options for clients and therapists can only be a good thing.
                        </p>
                    </div>
                    <div className="works-card-right">
                        <img src={require('../assets/emote-globe.png')} />
                    </div>
                </div>
                <div className="works-card">
                    <div className="works-card-left">
                        <img src={require('../assets/emote-anon2.png')} />
                    </div>
                    <div className="works-card-right">
                        <h5>GRANULAR PRIVACY SETTINGS</h5>
                        <h3>Added Privacy layers</h3>
                        <p>
                            By providing flexible and thorough privacy settings, people living in sensative or dangerous situations can still access therapy in complete anonymity - including a full incognito mode for our app.
                        </p>
                        <p>
                            Additionally, users can choose to release or withhold any of their information at any time, offering complete autonomy of choice.
                        </p>
                    </div>
                </div>
                <div className="works-card card2bg">
                    <div className="works-card-left">
                        <h5>CREATIVITY</h5>
                        <h3>Personalised user experience</h3>
                        <p>
                            Because of our underlying technology our user will own all of their assets and experiences opening them up to creating personalised ecosystems and use experiences. Be it from creative therapy, interactive journaling tools or even creating what your interface looks like, the chance to express yourself is invaluable in your therapeutic process.
                        </p>
                    </div>
                    <div className="works-card-right">
                        <img src={require("../assets/emote-journal.png")} />
                    </div>
                </div>

                <div className="motto-works">
                    We want people to have greater accessibility to therapy and complete ownership of their mental health records and anonymity.
                </div>
                <p className="motto-p">
                    If you'd like to be part of our journey then please join our waitlist
                </p>
                <JoinWaitlist />
            </div>
            <HWFooter />
        </div>
    )
}