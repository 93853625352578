import "./HomeFooter.css";
import JoinWaitlist from "./JoinWaitlist";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";

export default function HomeFooter() {
    return (
        <div className="homefooter-container">
            <div className="p-12 text-3xl font-bold">Join waitlist</div>
            <div className="p-4 text-3xl">
                Flexible, personalised and secure service for both therapists and clients
            </div>
            <div className="p-5 text-xl">
                Sign up to receive early access
            </div>
            <div className="waitlist-button">
                <JoinWaitlist />
            </div>
            {/* <div className="hilltop">
                <Logo />
                <div className="p-8 text-xl">
                    It's ok to not be ok
                </div>
                <div>
                    <SocialButtons />
                </div>
            </div> */}
        </div>
    )
}