import "./Home.css";

// import BlackButton from "../Components/BlackButton";
import HomeAboutBlock from "../Components/HomeAboutBlock";
// import InvestmentPackages from "../Components/InvestmentPackages";
// import JoinWaitlist from "../Components/JoinWaitlist";
import JoinMaillistForm from "../Components/JoinMaillistForm";
import SocialButtons from "../Components/SocialButtons";
// import SolveBlock from "../Components/SolveBlock";
import SolveBlockNew from "../Components/SolveBlockNew";
import SpeakerButton from "../Components/SpeakerButton";
import HelpTC from "../Components/HelpTC";
import HomeFooter from "../Components/HomeFooter";
// import FadeIn from 'react-fade-in';
import { useEffect, useState } from "react";
import Contact from "./Contact";

export default function HomeNew({ setBgColor }) {
  useEffect(() => {
    setBgColor(null);
  }, []);

  const [isJoining, setIsJoining] = useState(false);
  const [soundOn, setSoundOn] = useState(false);

  return (
    <div className="text-center">
      <div className="pt-20 pb-8 text-2xl bg-dark-brown">
        It's ok to not be ok
      </div>
      <audio
        id="player"
        autoPlay
        loop
        muted={!soundOn}
        src={require("../assets/emote_hero_loop.wav")}
      ></audio>
      <SpeakerButton soundOn={soundOn} setSoundOn={setSoundOn} />
      {isJoining && <JoinMaillistForm setIsJoining={setIsJoining} />}
      {/* <InvestmentPackages /> */}
      <div className="w-screen p-2 bg-dark-brown">
        <div className="mx-auto sm:w-3/5">
          <div className="text-3xl font-bold">
            A global marketplace for clients and therapists to connect through a
            web 3.0 network.
          </div>
        </div>
      </div>
      <div className="w-screen p-6 bg-dark-brown">
        <SocialButtons />
      </div>
      <div>
        <video
          className="video-container"
          autoPlay
          muted={!soundOn}
          loop={true}
        >
          <source
            src={require("../assets/Emote_hero1080.mp4")}
            type="video/mp4"
          />
        </video>
      </div>
      {isJoining && <JoinMaillistForm setIsJoining={setIsJoining} />}
      {/* <FadeIn> */}
      <div className="block-bg-container">
        <div className="blue-block-bg"></div>
        <div className="babyblue-block-bg"></div>
      </div>
      <HomeAboutBlock setIsJoining={setIsJoining} />
      <div className="p-10 text-3xl font-bold -mt-72">
        Building from our 3 core pillars
      </div>
      <SolveBlockNew setIsJoining={setIsJoining} />
      <div className="helptc-solver">
        <div className="p-10 text-3xl font-bold">
          Reasons to join emote Care
        </div>
        {/* <span className='home-solver-sub-headline'>
                        Here's how
                    </span> */}
      </div>
      <HelpTC />
      <div className="flex w-full p-10 mx-auto md:max-w-2xl lg:w-3/5 justify-evenly">
        {/*<BlackButton title='For therapists' link="fortherapists"/>  <BlackButton title='For clients' link="forclients" />*/}
      </div>
      {/* </FadeIn> */}

      <HomeFooter />
      <Contact setBgColor={setBgColor} />
    </div>
  );
}
