import "./Contact.css";

import BlackButton from "../Components/BlackButton";
import ContactFooter from "../Components/ContactFooter";

import emailjs from '@emailjs/browser';

import { useEffect, useState, useRef } from "react";

export default function Contact({setBgColor}) {

    const form = useRef();

    const [username, setUsername] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [textAreaValue, setTextAreaValue] = useState('Message');
    const [status, setStatus] = useState('');
    const [prospectType, setProspectType] = useState('client');

    useEffect(() => {
        setBgColor('blue');
    }, []);

    const sendEmail = (e) => {
        e.preventDefault();
    
        emailjs.sendForm(process.env.REACT_APP_EMAILJS_SERVICE_ID, process.env.REACT_APP_EMAILJS_TEMPLATE_ID, form.current, process.env.REACT_APP_EMAILJS_PUB_ID)
          .then((result) => {
              setUsername('');
              setUserEmail('');
              setTextAreaValue('');
              setProspectType('');
              console.log(result.text);
              setStatus('Thanks for reaching out! We will get in contact with you soon.');
              setTimeout(() => {
                setStatus('');
              }, 3000);
          }, (error) => {
              console.log(error.text);
              setStatus('Sorry - Something went wrong!');
              setTimeout(() => {
                setStatus('');
              }, 3000);
          });
      };
    
    return (
        <div className="bg-dark-brown">
            <div className="bg-sky-blue">
            <div className="flex">
                <img src={require('../assets/cloudLeft.png')} className="flex-none w-2/5 sm:w-1/3 md:w-1/4" />
                <div className="grow"></div>
                <img src={require('../assets/cloudRight.png')} className="flex-none w-2/5 sm:w-1/3 md:w-1/4" />
            </div>
                <div className="subcontainer">
                    <div className="pt-20 mx-auto text-xl lg:w-2/5">
                        If you have any questions please fill in the form below or send an email to <div className="font-bold">info@emotecare.com</div>
                    </div>
                    <form ref={form} className="flex flex-col p-10 mx-auto space-y-4 lg:w-3/5">
                        <input className="p-4 rounded-lg" type="text" placeholder="Name"  name="user_name" value={username} onChange={(e) => setUsername(e.target.value)} />
                        <input className="p-4 rounded-lg" type="email" placeholder="Email" name="user_email" value={userEmail} onChange={(e) => setUserEmail(e.target.value)} />
                        <select className="p-4 rounded-lg" placeholder="Therapist/Client/Other" name="prospect_type" value={prospectType} onChange={(e) => setProspectType(e.target.value)}>
                            <option value="" disabled defaultValue>Therapist/Client/Other</option>
                            <option value="therapist">Therapist</option>
                            <option value="client">Client</option>
                            <option value="other">Other</option>
                        </select>
                        <textarea
                            className="h-64 p-4 rounded-lg"
                            value={textAreaValue}
                            onChange={(e) => setTextAreaValue(e.target.value)}
                            rows={50}
                            cols={50}
                            name="message"
                            />
                        <div className="flex flex-row justify-end -translate-x-4 -translate-y-20" onClick={sendEmail}>
                            <BlackButton title={"Submit"} />
                        </div>
                        <div>
                            {status}
                        </div>
                    </form>
                </div>
            </div>
            {/*<div className="flex w-screen">
                <div className="w-1/3 aspect-square">
                    <img src={require('../assets/emote-lounge.png')} />
                </div>
                <div className="w-1/3">
                    <img src={require('../assets/midwall.png')} className="" />
                </div>
                <div className="w-96 aspect-square">
                    <img src={require('../assets/emote-plants.png')} className="" />
                </div>
            </div>
            <div className="bluefill"></div>*/}
            <ContactFooter />
        </div>

    );
}