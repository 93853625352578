import "./HomeAboutBlock.css"
import './FeatureCard.css';

import BlackButton from "./BlackButton";

export default function HomeAboutBlock({setIsJoining}) {
    return (
        <div className="-translate-y-72 w-4/5 mx-auto bg-no-repeat bg-center bg-sketch-border bg-[size:100%_100%] py-3">
            <div className="bg-white w-[95%] lg:w-[97%] lg:h-[95%] rounded-[80px] mx-auto">
                <div className="p-8 mx-auto text-xl font-bold sm:text-2xl md:text-3xl sm:w-4/5 md:w-3/5">
                    <h1>Revolutionizing the industry and providing greater accessibility and ownership of therapy</h1>
                    {/* <p>
                        Just some of the pain points we solve
                    </p> */}
                </div>
                <div className="flex flex-col w-full pb-10 lg:flex-row">
                    <div className="flex flex-col justify-evenly lg:w-1/4">
                        <div className="w-1/2 p-4 mx-auto aspect-square lg:w-2/3">
                            <img src={require('../assets/emote-client-owns-files.png')} className="object-contain aspect-square"/>
                        </div>
                        <div className="p-2 text-xl font-semibold lg:py-8">
                            Data ownership
                        </div>
                    </div>
                    <div className="w-full border-2 lg:w-fit border-dark-brown"></div>
                    <div className="flex flex-col justify-evenly lg:w-1/4">
                        <div className="w-1/2 p-4 mx-auto aspect-square lg:w-2/3">
                            <img src={require('../assets/emote-anonymity.png')} className="object-contain aspect-square"/>
                        </div>
                        <div className="p-2 text-xl font-semibold lg:py-8">
                            Privacy
                        </div>
                    </div>
                    <div className="w-full border-2 lg:w-fit border-dark-brown"></div>
                    <div className="flex flex-col justify-evenly lg:w-1/4">
                        <div className="w-1/2 p-4 mx-auto aspect-square lg:w-2/3">
                            <img src={require('../assets/emote-globe.png')} className="object-contain aspect-square"/>
                        </div>
                        <div className="p-2 text-xl font-semibold lg:py-8">
                            Transparent marketplace
                        </div>
                    </div>
                    <div className="w-full border-2 lg:w-fit border-dark-brown"></div>
                    <div className="flex flex-col justify-evenly lg:w-1/4">
                        <div className="w-1/2 p-4 mx-auto aspect-square lg:w-2/3">
                            <img src={require('../assets/emote-tool.png')} className="object-contain aspect-square"/>
                        </div>
                        <div className="p-2 text-xl font-semibold lg:py-8">
                            Security 
                        </div>
                    </div>
                </div>
                {/*<div onClick={() => {
                    setIsJoining(true)
                }} className="py-8">
                    <BlackButton title='Join the waitlist' />
            </div>*/}
            </div>
        </div>        
    )
}