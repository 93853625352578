import "./Logo.css";

export default function Logo() {
    return (
        <div className="logo">
            <div className="p1">
                emote
            </div>
            <div className="p2">
                CARE
            </div>
        </div>
    )
}