import { useEffect } from 'react';
import { Link } from "react-router-dom";

import './BlackButton.css';

export default function BlackButton({title, link}) {

    return (
            <div>
                {link && (
                    <div className="black-button">
                        <Link
                            to={`/${link}`}
                            style={{
                                color: "white",
                                textDecoration: "none"
                            }}>
                            {title}
                        </Link>
                    </div>
                )}
                {!link && (
                    <div className="black-button">
                        {title}
                    </div>
                )}
            </div>

    )
}