import "./HWFooter.css";
import JoinWaitlist from "./JoinWaitlist";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";


export default function HWFooter() {
    return (
        <div className="hwfooter-container">
            <div className="hilltop">
                <Logo />
                <div className="motto">
                    It's ok to not be ok
                </div>
                <div>
                    <SocialButtons />
                </div>
            </div>
        </div>
    )
}