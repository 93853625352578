import "./ForClients.css"

import { useState, useEffect } from "react";

import BlackButton from "../Components/BlackButton";
import ClientsFooter from "../Components/ClientsFooter";
import JoinWaitlist from "../Components/JoinWaitlist";
import JoinMaillistForm from "../Components/JoinMaillistForm"

export default function ForClients({setBgColor}) {

    const [isJoining, setIsJoining] = useState(false);

    useEffect(() => {
        setBgColor("blue");
    }, []);

    return (
        <div style={{backgroundColor:"#ace6f2"}}>
            <div className="forclients-top">
                <div  style={{zIndex:"2"}} >
                    <img src={require('../assets/emote-client-desk.png')}  />
                </div>
                <div className="about-header">
                    <h1>
                        1 in 4 people suffer with mental health issues every year 
                    </h1>
                    <p>
                        Begin your mental health journey securely with no subscription cost
                    </p>
                    <div onClick={() => setIsJoining(true) }>
                        <BlackButton title={"Join waitlist"} />
                    </div>
                </div>
            </div>
            {isJoining && <JoinMaillistForm setIsJoining={setIsJoining}/>}
            <div className="forclients-mid">
                {/* <h2>
                    With our secure Web 3.0 solutions we have your privacy and mental health as our primary focus
                </h2> */}
                {/* <p className="sub-p">Here's how</p> */}
                <div className="clients-card-container">
                    <div className="works-card">
                        <div className="img-cont">
                            <img src={require('../assets/emote-client-owns-files.png')} />
                        </div>
                        <h3>Data ownership</h3>
                    </div>
                    <div className="works-card">
                        <div className="img-cont">
                            <img src={require('../assets/emote-hand-world.png')} id="two"/>
                        </div>
                        <h3>Representation, availability and onboarding</h3>
                    </div>
                    <div className="works-card">
                        <div className="img-cont">
                            <img src={require('../assets/emote-lock-chill.png')} />
                        </div>
                        <h3>Security</h3>
                    </div>
                </div>
            </div> 

            <div className="black-waitlist-clients-container">
                <div>
                    <h3>Join the waitlist</h3>
                    <p>
                        Sign up to receive updates and early access opportunities
                    </p>
                </div>
                <div>
                    <JoinWaitlist buttonStyleObj={{backgroundColor:"orange", color: "black"}}/>
                </div>
            </div>
            <div className="forclients-inhands-container">
                <div className="forclients-mid-low-container">
                    <h2>Therapy in your hands</h2>
                    <h5>
                        The need for support can arise anywhere and at any time so we have built emote Care as the perfect therapy companion
                    </h5>
                    {/* <p>
                        Whether you're struggling at home, finding things hard during a holiday or overwhelmed on a crowded train, we are with you. As well as offering structred on-going therapy solutions, we also provide instant chat support with professionals, an open diary system to available therapists all around the world and audio support for those who'd simply prefer a comfortable phone call.
                    </p> */}
                    <BlackButton title={'See how it works'} link="/"/>
                </div>
            </div>
            <div>
                <div className="diversebg-container">
                    <img src={require('../assets/diversebg-emote.png')} />
                </div>
            </div>
            <div className="fc-finrun-container">
                <div>
                    <h2>How it works</h2>
                    {/* <p>A system built for you</p> */}
                </div>
            </div>
            <div className="fc-bigrun-container">
                <div className="process-container">
                    <div>
                        <img src={require('../assets/face1-emote.png')} />
                    </div>
                    <div>
                        <h3>
                            Setup your profile and privacy settings
                        </h3>
                        {/* <p>
                            When it comes to therapy, it certainly isn't one size fits all service. Whether you feel the importance of sharing your entire story with your therapist, or feel it's best to remain entirely anonymous, then you have full control on how much personal information you share at any time
                        </p> */}
                    </div>
                </div>
                <div className="process-container">
                    <div>
                        <img src={require('../assets/coins1-emote.png')} />
                    </div>
                    <div>
                        <h3>
                            Setup your secure payment method
                        </h3>
                        {/* <p>
                            Using third party, secure payment methods, you will not disclose any information to emote Care when setting up how you will pay for your sessions. Whether you choose to pay using your native currency from your bank accounts or via our alternative digital payment methods, you are always secure and private.
                        </p> */}
                    </div>
                </div>
                <div className="process-container">
                    <div>
                        <img src={require('../assets/icon-globe-emote.png')} />
                    </div>
                    <div>
                        <h3>
                            Find a therapist that best fits your personal and cultural needs
                        </h3>
                        {/* <p>
                            Utilising web3 has many benefits, including being able to offer more global accessibility. However you identify, whatever your culture we will have a therapist that fits what you need. 
                        </p> */}
                    </div>
                </div>
                <div className="process-container">
                    <div>
                        <img src={require('../assets/speech-bub-emote.png')} />
                    </div>
                    <div>
                        <h3>
                            Decide exactly how you'd like to engage in therapy
                        </h3>
                        {/* <p>
                            How you'd like to engage with your therapy - f2f, audio or chat, which modality of therapy you'd like to receive and how much personal information you want to share before engaging with your therapist.
                        </p> */}
                    </div>
                </div>
                <div className="process-container">
                    <div>
                        <img src={require('../assets/lock-emote.png')} />
                    </div>
                    <div>
                        <h3>
                            Know your data is securely stored and in your hands
                        </h3>
                        {/* <p>
                            No matter how you interact with emote Care you can feel safe in the knowledge that you are in control of your own data. On top of that, our web3 technology means safety and privacy like never seen before. 
                        </p> */}
                    </div>
                </div>
                {/* <div>
                    <h2>
                        Therapy in your hands
                    </h2>
                    <div style={{fontSize:"20px"}}>
                        emote is the perfect therapy companion wherever and whenever you need support
                    </div>
                </div> */}

                <div className="fc-fin-line">
                    <h2>
                        Start therapy on your terms, not someone elses.
                    </h2>
 
                    <JoinWaitlist />
                </div>
            </div>
                <ClientsFooter />

        </div>
    )
}