import "./JoinWaitlist.css";

import {useState} from "react";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getDatabase, ref, set } from "firebase/database";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    // The value of `databaseURL` depends on the location of the database
    databaseURL: process.env.REACT_APP_DATABASE_URL,
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Realtime Database and get a reference to the service
const database = getDatabase(app);
  
function writeUserData(email) {
    console.log("submitting to waitlist")
    const db = getDatabase();
    set(ref(db, 'users/' + email.slice(0,email.indexOf('@')) + Math.random().toString(36).slice(2,10)), {
      email: email,
    });
  }

export default function JoinWaitlist({buttonStyleObj}) {

    const [email, setEmail] = useState('');
    const [hasSubmitted, setHasSubmitted] = useState(false);

    const isEmail = (email) => /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

    const handleSubmit = () => {
      if (!isEmail(email)) {
        alert("invalid email address");
      } else {
        writeUserData(email);
        setHasSubmitted(true);
        setTimeout(() => {
          setHasSubmitted(false);
          setEmail("");
        }, 5000);
      }
    }
 
    return (
      <div>
        <div className="flex flex-col p-4 mx-auto space-y-4 sm:w-4/5 md:3/5 lg:w-2/5 lg:space-y-0 lg:flex-row">
          {!hasSubmitted && (
            <input className="self-center w-full h-12 px-4 text-lg border-2 border-black rounded-full lg:w-4/5 lg:translate-x-5 lg:rounded-r-none" type="text" placeholder="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
          )}

          <button className="w-40 px-6 text-lg text-white bg-black rounded-full whitespace-nowrap lg:-translate-x-5" style={ buttonStyleObj ? buttonStyleObj : null } onClick={() => {
            handleSubmit();
          }}>{!hasSubmitted ? "Join waitlist" : "Joined!"}</button>
        </div>
      </div>
    )
}