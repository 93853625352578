import zIndex from '@mui/material/styles/zIndex';
import { BsFillVolumeUpFill,BsFillVolumeMuteFill } from 'react-icons/bs';
import {useEffect} from "react";
export default function SpeakerButton({soundOn, setSoundOn}) {
    // useEffect(() => {
    //     setSoundOn(true);
    // }, [])

    return (
        <div>
            {soundOn && (
                <BsFillVolumeUpFill size="40px"
                style={{
                    position: "fixed",
                    bottom: "10",
                    left: "20px",
                    zIndex: "3",
                    color: "rgba(0,0,0,0.5)",
                    cursor: "pointer"
                }} 
                onClick={() => setSoundOn(false)}
                />
            )}
            {!soundOn && (
                <BsFillVolumeMuteFill size="40px"
                style={{
                    position: "fixed",
                    bottom: "10",
                    left: "20px",
                    zIndex: "3",
                    color: "rgba(0,0,0,0.5)",
                    cursor: "pointer"
                }} 
                onClick={() => setSoundOn(true)}
                />
            )}
        </div>


    )
}