import { SiDiscord } from 'react-icons/si';
import { SiTwitter } from 'react-icons/si';
import { FaTelegramPlane } from 'react-icons/fa';

import "./SocialButtons.css";

export default function SocialButtons() {
    return (
        <div className="flex justify-between w-48 p-8 mx-auto">
            <a href="http://discord.gg/BmrjZvPBZZ" style={{color: "black"}} target="_blank">
                <SiDiscord size="30px" />
            </a>
            <a href="https://twitter.com/emoteCare_" style={{color:"black"}} target="_blank">
                <SiTwitter size="30px" />
            </a>
            <a href="https://t.me/emoteCare" style={{color: "black"}} target="_blank">
                <FaTelegramPlane size="30px" />
            </a>
        </div>
    )
}