import BlackButton from "./BlackButton";
import "./SolveBlockNew.css";

export default function SolveBlockNew({setIsJoining}) {
    return (
            <div className="bg-black solveblock-body">
                <div>
                    <div className="flex flex-col bg-dark-brown" style={{borderBottomLeftRadius:"25%"}}>
                        <div className="flex flex-col px-10 py-24 md:px-12 ld:px-20 md:flex-row">
                            <div className="w-2/3 mx-auto">
                                <div className="pb-10 font-bold">Data ownership and privacy</div>
                                <img src={require('../assets/marktrans-client.png')} className="md:p-8"  />
                            </div>
                            <div className="flex-col p-4 py-12 md:py-4">
                                <img src={require('../assets/emote-priv-step1.png')} className="hidden h-96 md:flex" />
                                <img src={require('../assets/emote-priv-step1-h.png')} className="flex w-full md:hidden" />
                                <div className="text-lg font-bold">Privacy layer</div>
                            </div>
                            <div className="w-2/3 mx-auto">
                                <div className="pb-10 font-bold">Representation, availability and ease of onboarding</div>
                                <img src={require('../assets/emote-globe2.png')} className="md:p-8" />
                            </div>
                            <div className="flex-col p-4 py-12 md:py-4">
                                <img src={require('../assets/emote-priv-step1.png')} className="hidden h-96 md:flex" />
                                <img src={require('../assets/emote-priv-step1-h.png')} className="flex w-full md:hidden" />
                                <div className="text-lg font-bold">Privacy layer</div>
                            </div>
                            <div className="w-2/3 mx-auto">
                                <div className="pb-10 font-bold">Autonomy over session fees and scheduling</div>
                                <img src={require('../assets/marktrans-card3.png')} className="md:p-8"  />
                            </div>
                        </div>    
                        <div className="items-center justify-around hidden w-screen pb-10 mx-auto md:flex md:pr-2 md:pl-4 md:w-4/5">
                            <div className="w-1/3">
                                <img src={require('../assets/marktrans-arrow-left.png')} />
                            </div>
                            <div className="px-4 text-lg font-bold">Market Transparency</div>
                            <div className="w-1/3">
                                <img src={require('../assets/marktrans-arrow-right.png')} />
                            </div>
                        </div>               
                        <div className="button-container">
                            {/* <BlackButton title='See how it works' link="#"/> */}
                        </div>
                    </div>
                    <div className="bg-dark-brown">
                        <div className="pt-24 pb-28 curve-black">
                            <h5>
                                Using Web 3.0
                            </h5>
                            <div className="p-6 mx-auto text-5xl font-semibold text-white lg:w-4/5 sm:text-6xl">
                                Because without security and privacy there is no therapy
                            </div>
                            {/*<div className="pt-16">
                                <button className="" onClick={() => setIsJoining(true)}>
                                    Join waitlist
                                </button>
                            </div>*/}
                        </div>
                    </div>

                </div>
            </div>
        
    )
}