import { useState } from "react";

import JoinWaitlist from "./JoinWaitlist";

export default function SubscribeForm({ setIsJoining }) {

    const [status, setStatus] = useState(null)
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")

    return (
        <div style={{
            position: "fixed",
            top: "50%",
            maxWidth: "95%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            padding: "10px",
            color: "black",
            backgroundColor: "#ACE7F2",
            border: "2px solid black",
            boxShadow: "10px 5px 5px black",
            borderRadius: "15px",
            zIndex: 4
        }}>
            {status === "SUCCESS" && (
                <div>
                    <p>
                        Welcome aboard{name ? `, ${name}` : ""}{" "}
                        <span role="img" aria-label="Ship">
                            🚢
                        </span>
                    </p>
                    <p>Please check your inbox to confirm the subscription!</p>
                </div>
            )}
            {status === "ERROR" && (
                <div>
                    <p>Oops, something went wrong...</p>
                    <p>
                        Please,{" "}
                        <button onClick={() => setStatus(null)}>try again.</button>
                    </p>
                </div>
            )}
            {status === null && (
                <div>
                    <div
                        style={{
                            position: "relative",
                            left: "45%",
                            cursor: "pointer",
                            
                        }}
                        onClick={() => {
                            setIsJoining(false);
                        }}
                    >x</div>
                    {/* <p>Sign up to receive early access</p> */}
                    <JoinWaitlist />
                </div>
            )}
        </div>
    )
}