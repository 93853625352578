import "./ContactFooter.css";
import JoinWaitlist from "./JoinWaitlist";
import Logo from "./Logo";
import SocialButtons from "./SocialButtons";

export default function ContactFooter() {
  return (
    <div className="contactfooter-container">
      <div className="content-center w-1/2 mx-auto translate-y-24 sm:w-1/3 md:w-1/4">
        <img src={require("../assets/emote-client-desk.png")} className="" />
      </div>
      <div className="hilltop">
        <Logo />
        <div className="motto">It's ok to not be ok</div>
        <div>
          <SocialButtons />
        </div>
      </div>
    </div>
  );
}
