import "./App.css";

import * as React from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";

import Contact from "./Pages/Contact";
import ForClients from "./Pages/ForClients";
import ForTherapists from "./Pages/ForTherapists";
import HomeNew from "./Pages/HomeNew";
import HowItWorks from "./Components/HowItWorks";
import Layout from "./Components/Layout";

export default function App() {

  const [bgColor, setBgColor] = React.useState('');

  return (
    <div className="bg-dark-brown">
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <Routes>
        <Route path="/" element={<Layout bgColor={bgColor} setBgColor={setBgColor} />}>
          <Route index element={<HomeNew setBgColor={setBgColor} />} />
          <Route path="contactus" element={<Contact setBgColor={setBgColor} />} />
          <Route path="howitworks" element={<HowItWorks setBgColor={setBgColor} />} />
          <Route path="forclients" element={<ForClients setBgColor={setBgColor} />} />
          <Route path="fortherapists" element={<ForTherapists setBgColor={setBgColor} />} />
          {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </div>
  );
}

function Dashboard() {
  return (
    <div>
      <h2>Dashboard</h2>
    </div>
  );
}

function NoMatch() {
  return (
    <div>
      <h2>Nothing to see here!</h2>
      <p>
        <Link to="/">Go to the home page</Link>
      </p>
    </div>
  );
}
