import "./ForTherapists.css";

import { useState, useEffect } from "react";
import BlackButton from '../Components/BlackButton'
import FTFooter from "../Components/FTFooter";
import JoinWaitlist from '../Components/JoinWaitlist'
import JoinMaillistForm from "../Components/JoinMaillistForm"

export default function ForTherapists({setBgColor}) {

    const [isJoining, setIsJoining] = useState(false);

    useEffect(() => {
        setBgColor("green");
    })
    return (
        <div >
        <div style={{backgroundColor:"#b1cc4f"}} className='fortherapists-main'>
            <div className="howitworks-top">
                <div className="about-header" style={{backgroundColor:"#b1cc4f"}}>
                    <h1>
                        Connect with clients from around the globe
                    </h1>
                    {/* <p>
                        Having to work within the confines of geography and high overheads? We have a solution to give you autonomy in what you love best, helping people in your unique approach and style. Built by therapists for therapy
                    </p> */}
                    <div onClick={() => setIsJoining(true) }>
                        <BlackButton title={"Join waitlist"} />
                    </div>
                </div>
                <div  style={{zIndex:"2"}}>
                    <img src={require('../assets/emote-thera-desk.png')} />
                </div>
            </div>
            {isJoining && <JoinMaillistForm setIsJoining={setIsJoining}/>}
            <div className="fortherapists-mid">
                <h2>
                    Reasons for therapists to onboard
                </h2>
                {/* <p className="sub-p">Here's how</p> */}
                <div className="fortherapists-card-container">
                    <div className="works-card">
                        <img src={require('../assets/emote-hand-world.png')} />
                        <p>
                            Global client base
                        </p>
                    </div>
                    <div className="works-card">
                        <img src={require('../assets/emote-girl-laptop.png')} style={{width: '125px'}} />
                        <p>
                            Autonomy over scheduling and fees
                        </p>
                    </div>
                    <div className="works-card">
                        <img src={require('../assets/emote-lock-chill.png')} style={{width: "150px"}} />
                        <p>
                            Safe and ethical practices
                        </p>
                    </div>
                </div>
            </div>
            <div>    
                <div className="fortherapists-mid-low-container">
                    <h2>Tailored to your life</h2>
                    {/* <p>
                        With a global cilent pool and flexibility in how you work, we provide the opportunity to deliver therapy how, where and when you want. Provide chat support on the go, make yourself available for drop ins when you have some free time or open your calendar for ongoing weekly therapy, emote Care provides you the flexibility of choice
                    </p> */}
                </div>

                <div className="tailor-blocks">
                    <div className='row-tailor-container'>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-clock.png')} />
                            <p>
                                Flexible working conditions
                            </p>
                        </div>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-check.png')} />
                            <p>
                                Complete autonomy over session fees and scheduling
                            </p>
                        </div>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-globe.png')} />
                            <p>
                                Global client base and ease of onboarding
                            </p>
                        </div>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-health.png')} />
                            <p>
                                Complete autonomy over session fees and scheduling
                            </p>
                        </div>
                    </div>
                    <div className='row-tailor-container'>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-lock.png')} />
                            <p>
                                Work with the pinnacle of secure and private technology
                            </p>
                        </div>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-down.png')} />
                            <p>
                                Intuitive file storage and document templates
                            </p>
                        </div>
                        <div className='tailor-block'>
                            <img src={require('../assets/emote-icon-equity.png')} />
                            <p>
                                Receive a much higher hourly rate than competitors
                            </p>
                        </div>
                    </div>
                    {/* <BlackButton title={'See how it works'}  style={{maxWidth: "200px"}} link="howitworks" /> */}
                </div>

                <div className="black-fortherapists-break-container">
                    <div>
                        <h3>Join the waitlist</h3>
                        <p>
                            Sign up to receive updates and early access opportunities
                        </p>
                    </div>
                    <JoinWaitlist buttonStyleObj={{
                            backgroundColor:"#fdbd48",
                            color:"black"
                        }}  />
                </div>

                <div className="fortherapists-howitworks-container">
                    <h2>
                        How it works
                    </h2>
                    {/* <p className="sub-p">A system built for you</p> */}

                    <div className="time-container">
                        <div className="process-container">
                            <div>
                                <img src={require('../assets/emote-ribbon-icon.png')} />
                            </div>
                            <div>
                                <h3>SET UP A PROFILE</h3>
                                <h2>
                                    Make yourself visable
                                </h2>
                            </div>
                        </div>
                        <div className="process-container">
                            <div>
                                <img src={require('../assets/emote-setting-icon.png')} />
                            </div>
                            <div>
                                <h3>SELECT HOW YOU WORK</h3>
                                <h2>
                                    Setup the parameters for how you want to work
                                </h2>
                            </div>
                        </div>
                        <div className="process-container">
                            <div>
                                <img src={require('../assets/emote-globe2-icon.png')} />
                            </div> 
                            <div>
                                <h3>CONNECT</h3>
                                <h2>
                                    Connect with clients around the world
                                </h2>
                            </div>
                        </div>
                        <div className="process-container">
                            <div>
                                <img src={require('../assets/emote-pencil-icon.png')} />
                            </div>
                            <div>
                                <h3>PROVIDE THERAPY ON YOUR TERMS</h3>
                                <h2>
                                    Add value to their therapeutic journey with your work 
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div className="fortherapists-lifestyle-container">
                    <h2>
                        Live the lifestyle you want, while doing what you do best 
                    </h2>
                    <JoinWaitlist />
                </div>
            </div>
        
        </div>
        <div className="ftfooter-container">
            <FTFooter />
        </div>
        </div>

    )
}